import React from "react";
import { Row, Col, Card } from "react-bootstrap";
// rgb(229,229,229)

const About = () => {
  return (
    <Card style={{ borderRadius: 0, backgroundColor: "rgba(229,229,229,.65)" }}>
      <Card.Body>
        <Row className="d-flex">
          <Col xl={5} md={5} sm={12}>
            <h1 style={{ color: "black" }}>DEVJA</h1>
          </Col>
          <Col>
            <h4>We are proudly creating the finest software</h4>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default About;
