import React from "react";
import { Row, Col, Card } from "react-bootstrap";
// rgb(229,229,229)

const Feedbacks = () => {
  return (
    <Card style={{ borderRadius: 0, backgroundColor: "rgba(229,229,229,.65)" }}>
      <Card.Body>
        <Row className="d-flex">
          <Col className="d-flex align-items-center">
            <h1 style={{ color: "black" }}>Contact</h1>
          </Col>
          <Col className="d-flex align-items-center">
            <a href="mailto:contact@devja.pl" style={{ color: "black" }}>
              contact@devja.pl
            </a>
          </Col>
        </Row>
        <Row className="d-flex text-dark">
          <Col className="d-flex align-items-center">
            <span>Devja Soft - Adrian Klemczak</span>
          </Col>
          <Col className="d-flex align-items-center">
            <span>NIP: 9721341210</span>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default Feedbacks;
