import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import { pathRoutes } from "../../routes";
import { Link } from "react-router-dom";

export const Menu = () => {
  const buttons = ["Home", "About", "Contact"];
  return (
    <>
      <Row
        className=" menu-box "
        // style={{ boxShadow: "0px -28px 52px 1px rgba(232, 232, 232, 1)" }}
      >
        {buttons.map((button) => (
          <Button
            variant="link"
            as={Link}
            to={pathRoutes[button].path}
            className="shadow-none  menu-nav-button text-end menu-button"
          >
            {button}
          </Button>
        ))}
      </Row>
    </>
  );
};
