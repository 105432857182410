import React from "react";
import { Row, Col, Card } from "react-bootstrap";
// rgb(229,229,229)

const Experience = () => {
  const webTechnologies = ["React.js", "Angular", "Django", "Micronauts"];
  const otherTechnologies = ["React-Native", "OpenCV", "TensorFlow", "Keras"];
  return (
    <>
      <Row className="d-flex mb-4">
        <Col xl={8} md={8} sm={12}>
          <Card
            style={{
              borderRadius: 0,
              backgroundColor: "rgba(229,229,229,.65)",
            }}
          >
            <Card.Body>
              <Row className="d-flex " style={{ color: "black" }}>
                <Col xl={6} md={6} sm={12}>
                  <span>
                    Our main expertise lies in developing software for the
                    railway industry.
                  </span>
                </Col>
                <Col xl={6} md={6} sm={12}>
                  <span>
                    However, our team stays up-to-date with the latest trends,
                    enabling us to provide software developed using the newest
                    technologies.
                  </span>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="d-flex">
        <Col xl={8} md={8} sm={12}>
          <Card
            style={{
              borderRadius: 0,
              backgroundColor: "rgba(229,229,229,.65)",
            }}
          >
            <Card.Body>
              <Row className="d-flex " style={{ color: "black" }}>
                <Col xl={5} md={5} sm={12}>
                  <h2>Technologies in which we excel:</h2>
                </Col>
                <Col xl={3} md={3} sm={12}>
                  <span>Web:</span>
                  <ul>
                    {webTechnologies.map((item) => (
                      <li>{item}</li>
                    ))}
                  </ul>
                </Col>
                <Col xl={3} md={3} sm={12}>
                  <span>Other:</span>
                  <ul>
                    {otherTechnologies.map((item) => (
                      <li>{item}</li>
                    ))}
                  </ul>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Experience;
