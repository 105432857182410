import About from "./components/About";
import Experience from "./components/Experience";
import Feedbacks from "./components/Feedbacks";
import { Menu } from "./components/nav/navbar";
import React from "react";
import { Route, Routes } from "react-router-dom";
import { pathRoutes } from "./routes";
import { ParticlesBackground } from "./components/Particles";
const HomePage = () => {
  const elements = [
    { component: <About />, path: pathRoutes.Home.path },
    { component: <Experience />, path: pathRoutes.About.path },
    { component: <Feedbacks />, path: pathRoutes.Contact.path },
  ];
  return (
    <Routes>
      {elements.map((item) => (
        <Route
          path={item.path}
          element={
            <>
              <main>
                <Menu />
                <ParticlesBackground />

                <div className="component-box">{item.component}</div>
              </main>
            </>
          }
        />
      ))}
    </Routes>
  );
};

export default HomePage;
